import Carousel from 'react-bootstrap/Carousel';

function PowerCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/7.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/20.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/21.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/22.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/23.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/24.jpg"
          alt="Second slide"
        />  
      </Carousel.Item>
      
    </Carousel>
  );
}

export default PowerCarousel;