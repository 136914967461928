import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';


const Services = () => {

useEffect(()=>{
document.title = "Our Services | Safe Solar Uganda";

},[]);

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}


  return (
    <section className='section-cover services-cover' >
      <h2 className="home-h2 services-h2">Our Services</h2>
           <div className='content-container services-container'>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Supply, Installation & Maintenance of Solar Systems</h3>
           
                     {/* <p>
    We have brought you both new and refurbished medical equipment to your doorsteps in Uganda. We understand the convergence point between high-quality medical equipment & affordability. You'll find both at ELK'S Medicals Limited.
                   
                     </p> */}
                  </article>

                  <article className='service-slides'>
                    <TelecomCarousel></TelecomCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Supply, Installation & Maintenance of Power Backups</h3>
                     
                     {/* <p>
               
                     
                     </p> */}
                  </article>

                  <article className='service-slides'>
                    <PowerCarousel></PowerCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Supply, Installation & Maintenance of Solar Water Heaters</h3>
                       
                       {/* <p>
              
                     
                     </p>
                     <Link to='/rental-services' className='home-btn' onClick={scrollFunc}>Read More</Link> */}

                  </article>

                  <article className='service-slides'>
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Supply, Installation & Maintenance of Water Pumps & Irrigation Systems</h3>
                    {/* <p></p> */}
                   
                  </article>

                  <article className='service-slides'>
                    <SupportCarousel></SupportCarousel>
                 
                  </article>     
            </div>
            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Supply, Installation & Maintenance of Batteries</h3>
                       
                       {/* <p>
        
                     
                     </p>
                     <Link to='/rental-services' className='home-btn' onClick={scrollFunc}>Read More</Link> */}

                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                 
                  </article>     
            </div>
            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Supply, Installation & Maintenance of Inverters</h3>
                    {/* <p></p> */}
                   
                  </article>

                  <article className='service-slides'>
                    <EmergencyCarousel></EmergencyCarousel>
                 
                  </article>     
            </div>
            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Supply, Installation & Maintenance of Air Conditioners</h3>
                       
                       {/* <p>
        
                     
                     </p>
                     <Link to='/rental-services' className='home-btn' onClick={scrollFunc}>Read More</Link> */}

                  </article>

                  <article className='service-slides'>
                    <BuildingsCarousel></BuildingsCarousel>
                 
                  </article>     
            </div>
            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Plumbing Works</h3>
                    {/* <p></p> */}
                   
                  </article>

                  <article className='service-slides'>
                    <Plumbing></Plumbing>
                 
                  </article>     
            </div>
             <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Electrical Works</h3>
                       
                       {/* <p>
        
                     
                     </p>
                     <Link to='/rental-services' className='home-btn' onClick={scrollFunc}>Read More</Link> */}

                  </article>

                  <article className='service-slides'>
                    <AC></AC>
                 
                  </article>     
            </div>



           </div>

    </section>
  )
}

export default Services