import Carousel from 'react-bootstrap/Carousel';

function RoadCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/4.jpg"
          alt="First slide"
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/16.jpg"
          alt="First slide"
        />

      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/34.jpg"
          alt="First slide"
        />

      </Carousel.Item>

      
    </Carousel>
  );
}

export default RoadCarousel;