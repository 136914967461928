import React from "react";

import Carousel from 'react-bootstrap/Carousel';

function CarouselFade() {
  return (
    <Carousel fade>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/4.jpeg"
          src="./images/10.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className="slide-h3">Safe Solar Uganda</h3>
          <p className="slide-p slide-p1">"Always Safe"</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/2.jpg"
           src="./images/9.jpg"
          alt="Second slide"
        />

        <Carousel.Caption>
          {/* <h3>We're Prominent In Engineering Works</h3> */}
          <p className="slide-p">Providing you with high-quality solar equipment</p>
        </Carousel.Caption>
      </Carousel.Item>
      

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/4.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p">
           We supply, install and maintain solar water heaters
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/1.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p">
           We are trusted for authentic power backup equipment
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/2.png"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p">
           We also provide air conditioning services
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselFade;